import Game from "./component/Game";

function App() {
  return (
    <div>
      <Game/>
    </div>
  );
}

export default App;
